import { useEffect, useState } from "react";
import { useMediaQuery, useTheme, Box, Grid, Button, Typography, Tooltip, IconButton } from '@mui/material';
import { useRules } from "../../hooks/useRules";
import AppInfoView from "../../components/AppBase/AppInfoView";
import SupportDocumentsCard from "./SupportDocumentsCard";
import RulesHeader from "../../components/RulesHeader";
import ModulesCard from "./ModulesCard";
import LoadingError from "../../components/LoadingError";
import RuleInstanceDetail from "./RuleInstanceDetail";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { RuleUnitState } from "../../types/models/RuleUnitState";
import { toggleRuleModal } from "../../redux/actions";
import { useDispatch } from "react-redux";
import EmptyResult from "./EmptyResult";
import AppScrollbar from "../../components/AppBase/AppScrollbar";
import FavouriteCard from "./FavouriteCard";
import AppDialog from '../../components/AppBase/AppDialog';
import RuleUnitFilter from "./RuleUnitFilter.tsx";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AppLoader from "../../components/AppBase/AppLoader";

export default function RulesPage() {
    const { getCurrentContentRevision, invalidAccess, isLoading, refreshRules, selectedRule } = useRules();
    const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(`(width <= ${theme.breakpoints.values.md}px)`);
    const { showRuleModal } = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
    const dispatch = useDispatch();
    const contentHeight = "calc(100vh - 100px)";
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
    const [modulesCollapsed, setModulesCollapsed] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent): void => {
          if (event.key === '[') {
            toggleModuleCards();
          }
        };
    
        // Add the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, []); 

    const toggleModuleCards = () => {
        setModulesCollapsed((prevState) => !prevState);
    }

    if(ruleUnitState.baseContentRevisions === undefined || isLoading) {
        return <AppLoader />
    }

    return (
        invalidAccess ?
            (<LoadingError />) :
            (
                <>
                    <Box display="grid" gridTemplateRows="min-content 1fr" minHeight="95vh" sx={{ backgroundColor: theme.page.bgColor }}>
                        <RulesHeader title="Rules" isRefreshing={isLoading} refreshPage={refreshRules} displayFilterButton={true} />
                        <Grid container sx={{ maxHeight: "95vh" }}>
                            <Grid item xs={12} md={6} lg={5} sx={{ pt:2, pl: 1, pr: 0.5, display: modulesCollapsed ? "none" : "block" }}>
                                {ruleUnitState.filterByRoles && (
                                    <Box sx={{
                                        ml: 1,
                                        mr: 1.5,
                                        mb: 1,
                                        borderRadius: 1,
                                        backgroundColor: theme.palette.primary.main,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",

                                    }}>
                                        <Typography sx={{ ml: 1, fontColor: theme.palette.secondary.main, fontSize: 14 }}>
                                            {`Filtered: ${ruleUnitState.selectedRoles?.length ?? 0} Roles, ${ruleUnitState.selectedLocations?.length ?? 0} Locations`}
                                        </Typography>
                                        <Button
                                            variant="text"
                                            onClick={() => { setIsOpenFilter(true) }}
                                            sx={{ color: "white", textTransform: "none", pr: 0 }}>
                                            Edit
                                        </Button>
                                    </Box>
                                )}
                                <AppScrollbar sx={{ height: contentHeight }}>
                                    <Box>
                                        {(ruleUnitState.myFavourites?.length ?? 0) !== 0 && (
                                            <Box sx={{ pl: 1, pr: 1.5, mb: 2 }}>
                                                <FavouriteCard />
                                            </Box>
                                        )}
                                        <Box sx={{ pl: 1, pr: 1.5 }}>
                                            <ModulesCard />
                                        </Box>
                                        <Box sx={{ mt: 2, pl: 1, pr: 1.5, mb: 1 }} component="section" id="support-documents">
                                            <SupportDocumentsCard searchText={ruleUnitState.searchText} supportContent={ruleUnitState.baseSupportDocumentContentRevisions} supportContentDetails={ruleUnitState.supportDocumentContentRevisionDetails} />
                                        </Box>
                                    </Box>
                                </AppScrollbar>
                            </Grid>
                            <Box className={`${modulesCollapsed ? "module-collapse-button-collapsed" : "module-collapse-button"}`}>
                            <Tooltip title="Collapse or expand the navigation [ (left bracket)" placement="right">
                                <IconButton className="collapse-button"  sx={{display: { xs: "none", md: "flex" }, color: "#000000", backgroundColor: "#ffffff", borderRadius: "9999px", zIndex: 99, border: "1px solid #d9d8db", '&:hover': { backgroundColor: '#f57c00', color: "#ffffff", border: "none" }}} onClick={toggleModuleCards}>
                                    <ChevronLeftIcon fontSize="small" className={`${modulesCollapsed ? "rotate-180" : ""}`} />
                                </IconButton>
                            </Tooltip>
                        </Box>

                            {/* Rule Content Area */}
                            <Grid item xs={12} md={modulesCollapsed ? 12 : 6} lg={modulesCollapsed ? 12 : 7} sx={{ ml: modulesCollapsed ? 2 : 0}}>
                                {isSmallScreen ? (
                                    // Modal version
                                    <RuleInstanceDetail
                                        isModal={true}
                                        key={selectedRule?.RuleInstanceId}
                                        displayFeedbackButtons={false}
                                        contentRevision={getCurrentContentRevision()}
                                        rule={selectedRule}
                                        isShowDetail={showRuleModal}
                                        setIsShowDetail={() => { dispatch(toggleRuleModal(false)) }}
                                        displayFavouritePinButton={true}
                                    />
                                ) : (
                                    <Box py={2} pr={2} >
                                        {selectedRule ? (
                                            <AppScrollbar sx={{ height: contentHeight }}>
                                                <Box sx={{ px: 2, py: 1 }} minHeight={contentHeight} border={1} bgcolor="white" borderColor="grey.200" borderRadius={1} flexGrow={1}>
                                                    <RuleInstanceDetail
                                                        key={selectedRule?.RuleInstanceId}
                                                        displayFeedbackButtons={false}
                                                        isModal={false}
                                                        contentRevision={getCurrentContentRevision()}
                                                        rule={selectedRule}
                                                        displayFavouritePinButton={true}
                                                    />
                                                </Box>
                                            </AppScrollbar>
                                        ) : (
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid", borderColor: "grey.200", backgroundColor: "white", height: contentHeight }}>
                                                <EmptyResult>Click on an item to see more details.</EmptyResult>
                                            </Box>
                                        )}
                                    </Box>)
                                }
                            </Grid>
                        </Grid>
                    </Box >
                    <AppInfoView />
                    <AppDialog
                        open={isOpenFilter}
                        title=""
                        fullHeight={true}
                        hideClose={true}
                        onClose={() => { setIsOpenFilter(false) }}>
                        <RuleUnitFilter></RuleUnitFilter>
                    </AppDialog >
                </>
            )
    );
}
