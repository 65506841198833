/**
 * @name Hook: useGetRules
 * @description A custom hook that retrieves rule unit and role state from the Redux store,
 *    and provides functions to get content revisions, refresh the page, and handle target items.
 * @returns An object containing the content revision, invalid access flag, loading state, refresh function, target item and selectedRule.
 */

import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store";
import { clearAllState, getContentById, getContentList, getFavouriteRules, getMyAcknowledgement, selectRule, setSelectedUserLocations, setSelectedUserRoles } from "../redux/actions";
import { getMyChatThreads } from "../redux/actions/Chat";
import { RuleUnitState } from "../types/models/RuleUnitState";
import { RoleState } from "../types/models/RoleState";
import { useSearchParams } from "react-router-dom";
import { getOrigin } from "../utils/RuleUtil";

// delay function used to create a delay between API calls
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function useRules() {
  const ruleUnitState = useSelector<AppState, RuleUnitState>((state) => state.ruleUnit);
  const { selectedRule, baseContentRevisionDetails, baseSupportDocumentContentRevisionDetails } = ruleUnitState;
  const roleState = useSelector<AppState, RoleState>((state) => state.role);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [invalidAccess, setInvalidAccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetItem, setTargetItem] = useState<{ type: 'rule' | 'module', id: string } | undefined>();

  const getCurrentContentRevision = useCallback(() => {
    const contentRevision = (baseContentRevisionDetails ?? [])
      .concat(baseSupportDocumentContentRevisionDetails ?? [])
      .find(detail =>
        detail.RuleInstances?.some(rule => rule.RuleInstanceId === selectedRule?.RuleInstanceId)
      );

    return contentRevision;
  }, [selectedRule, baseContentRevisionDetails, baseSupportDocumentContentRevisionDetails]);

  useEffect(() => {
    const ruleInstanceId = searchParams.get('ruleId');
    const moduleId = searchParams.get('moduleId');

    const content = baseContentRevisionDetails?.concat(baseContentRevisionDetails).concat(baseSupportDocumentContentRevisionDetails ?? []).map((content) => content.RuleInstances).flat();
    const origin = content?.find((rule) => getOrigin(rule?.Metadata) === ruleInstanceId);

    if(origin) {
      dispatch(selectRule(origin));
    }

    if (ruleInstanceId) {
      setTargetItem({ type: 'rule', id: ruleInstanceId });
    }
    else if (moduleId) {
      setTargetItem({ type: 'module', id: moduleId });
    }
    else {
      setTargetItem(undefined);
    }
  }, [searchParams]);

  // Initial load effect
  useEffect(() => {
    if (!roleState.knownRoles) return;

    const hasExistingContent = 
      (ruleUnitState.baseContentRevisions && ruleUnitState.baseContentRevisions.size > 0) ||
      (ruleUnitState.baseSupportDocumentContentRevisions && ruleUnitState.baseSupportDocumentContentRevisions.length > 0);

    if (!hasExistingContent) {
      getRules();
    }
  }, [roleState.knownRoles, ruleUnitState.baseContentRevisions, ruleUnitState.baseSupportDocumentContentRevisions]);

  useEffect(() => {
    if (ruleUnitState.baseAlertContentRevisions &&
      (!ruleUnitState.baseAlertContentRevisionDetails || ruleUnitState.baseAlertContentRevisionDetails.length === 0)) {
      // Call detail API if it's not called yet
      ruleUnitState.baseAlertContentRevisions.forEach(async (alertContent, index) => {
        if(index > 0) {
          await delay(500);
        }
        dispatch(getContentById(alertContent.ContentRevisionId, null));
      })
    }
  }, [ruleUnitState.baseAlertContentRevisions])


  useEffect(() => {
    if (selectedRule) {
      // Clear selection if the searched rules does not include selected rule
      if (!ruleUnitState.contentRevisionDetails?.find(detail => detail.RuleInstances?.some(rule => rule.RuleId === selectedRule.RuleId)) &&
        !ruleUnitState.supportDocumentContentRevisionDetails?.find(detail => detail.RuleInstances?.some(rule => rule.RuleId === selectedRule.RuleId))) {
        dispatch(selectRule(undefined));
      }
    }
  }, [dispatch, ruleUnitState.contentRevisionDetails, ruleUnitState.supportDocumentContentRevisionDetails, selectedRule]);

  const getRules = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Get acknowledgements first
      const ackResult = await new Promise<boolean>((resolve) => {
        dispatch(getMyAcknowledgement((result) => resolve(result)));
      });
      
      if (!ackResult) {
        setInvalidAccess(true);
        setIsLoading(false);
        return;
      }

        await delay(500);

      // Get chat threads
      await new Promise<void>((resolve) => {
        dispatch(getMyChatThreads(() => resolve()));
      });

      await delay(500);

      // Get favorites
      await new Promise<void>((resolve) => {
        dispatch(getFavouriteRules(() => resolve()));
      });

    } catch (error) {
      console.error('Error in getRules:', error);
      setInvalidAccess(true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const refreshRules = useCallback(async () => {
    try {
      setIsLoading(true);

      // Step 1: Clear state
      dispatch(clearAllState());

      // Step 2: Set up initial state
      dispatch(setSelectedUserRoles(roleState.myRoles?.map(role => role.RoleId) ?? []));
      dispatch(setSelectedUserLocations(roleState.myLocations?.map((location) => location.LNIId) ?? []));

      await delay(500);

      // Step 3: Get content list and wait for it to complete
      await new Promise<void>((resolve) => {
        dispatch(getContentList(() => resolve()));
      });

      await delay(500);

      // Step 4: Get rules and related data
      await getRules();

    } catch (error) {
      console.error('Error in refreshRules:', error);
      setInvalidAccess(true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, roleState.myRoles, roleState.myLocations, getRules]);

  return { getCurrentContentRevision, baseContentRevisionDetails, invalidAccess, isLoading, refreshRules, targetItem, selectedRule };
}